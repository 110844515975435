.dashboard table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dashboard table th,
  .dashboard table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .dashboard table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  