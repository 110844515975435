.configs table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .configs table th,
  .configs table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .configs table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .configs input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  