/* Menu.css */
.menu {
  width: 200px;
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  height: 100vh; /* Ensure it takes the full height of the viewport */
}

.menu ul {
  list-style-type: none;
  padding: 0;
  flex-grow: 1; /* Allow the list to grow and push the button to the bottom */
}

.menu ul li {
  margin: 10px 0;
}

.menu ul li a,
.menu ul li button {
  text-decoration: none;
  color: #007bff;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
}
