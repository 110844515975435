/* DashboardPage.css */
.dashboard-page {
  display: flex;
  height: 100vh; /* Ensure it takes the full height of the viewport */
}

.right-component {
  flex-grow: 1;
  padding: 20px;
  background-color: #f5f5f5;
}
